import React from 'react';
import { Layout, SEO } from 'components/common';
import { Aszf } from 'components/landing/Aszf';

export default () => (
  <Layout>
    <SEO />
    <Aszf/>
  </Layout>
);
