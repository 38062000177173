import React, { useContext } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Button } from 'components/common';
import { Header } from 'components/theme';
import kereszty from 'assets/images/kereszty_team.jpg';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';
import { FormattedMessage } from "gatsby-plugin-intl"

export const Aszf = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper id="works">
      <Header />
      <SkillsWrapper as={Container}>
        <Details theme={theme}>
          <div>
            <h2>Aszf</h2> 
            <p></p>
            <p></p>  
          </div>
          
        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};
